<template>
    <section class="product-series">
        <tool-bar v-bind:lang.sync="lang"
                  enable-translate
                  v-bind:translate="enableTcToSc">
            {{ mode }} a Product Series
            <template slot="buttons">
                <div class="level-item">
                    <button class="button"
                            v-if="mode === 'Create'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="save">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Save</span>
                    </button>
                    <button class="button"
                            v-if="mode === 'Update'"
                            v-bind:class="{ 'is-loading': loading }"
                            v-bind:disabled="!allowSave"
                            v-on:click="update">
                        <span class="icon is-small">
                            <font-awesome-icon icon="save"></font-awesome-icon>
                        </span>
                        <span>Update</span>
                    </button>
                </div>
            </template>
        </tool-bar>

        <div class="banner position-relative pb-4 d-flex">
            <button class="button edit"
                    v-on:click="setCover"
                    v-on:mouseover="revealImageElement"
                    v-on:mouseout="revealImageElement">
                <span class="icon is-small">
                    <font-awesome-icon icon="image"></font-awesome-icon>
                </span>
                <span>Change Cover</span>
            </button>
            <background-filter color="transparent"
                               v-bind:src="cover">
            </background-filter>
            <div class="container py-5 d-flex">
                <div class="row h-100 flex-1">
                    <div class="col-md-5 pr-md-4 pb-4 pb-md-0">
                        <div class="product-image position-relative text-center">
                            <button class="button edit"
                                    v-on:click="setImage"
                                    v-on:mouseover="revealImageElement"
                                    v-on:mouseout="revealImageElement">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="image"></font-awesome-icon>
                                </span>
                                <span>Change Image</span>
                            </button>
                            <img class="img-responsive" v-bind:src="image">
                        </div>
                    </div>
                    <div class="col-md-7 d-flex flex-column justify-content-center">
                        <medium-editor class="text-center text-md-left editable"
                                       custom-tag="h1"
                                       v-bind:style="{ 'color': headlineColor.rgba ? `rgba(${ headlineColor.rgba.r }, ${ headlineColor.rgba.g }, ${ headlineColor.rgba.b }, ${ headlineColor.rgba.a }) !important` : `rgba(${ headlineColor.r }, ${ headlineColor.g }, ${ headlineColor.b }, ${ headlineColor.a }) !important` }"
                                       v-bind:text="headline[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Headline', } }, options)"
                                       v-on:edit="processHeadlineEditOperation">
                        </medium-editor>

                        <medium-editor class="text-center text-md-left editable"
                                       custom-tag="h3"
                                       v-bind:style="{ 'color': headlineColor.rgba ? `rgba(${ headlineColor.rgba.r }, ${ headlineColor.rgba.g }, ${ headlineColor.rgba.b }, ${ headlineColor.rgba.a }) !important` : `rgba(${ headlineColor.r }, ${ headlineColor.g }, ${ headlineColor.b }, ${ headlineColor.a }) !important` }"
                                       v-bind:text="subheadline[lang]"
                                       v-bind:options="Object.assign({ placeholder: { text: 'Subheadline', } }, options)"
                                       v-on:edit="processSubHeadlineEditOperation">
                        </medium-editor>

                        <div class="position-relative">
                            <button class="button button-color-picker"
                                    v-on:click="isHeadlineColorModalActive = true">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="palette"></font-awesome-icon>
                                </span>
                                <span>Set headline color</span>
                            </button>
                            <color-picker class="position-absolute color-picker"
                                          v-bind:class="{ 'show': isHeadlineColorModalActive }"
                                          v-model="headlineColor">
                                <template slot="footer">
                                    <div class="button-color-default">
                                        <button class="button is-small"
                                                v-on:click="resetColorToDefault">
                                            Reset to Default
                                        </button>
                                    </div>
                                </template>
                            </color-picker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="description position-relative py-5">
            <button class="button edit"
                    v-on:click="setDescriptionBackground"
                    v-on:mouseover="revealImageElement"
                    v-on:mouseout="revealImageElement">
                <span class="icon is-small">
                    <font-awesome-icon icon="image"></font-awesome-icon>
                </span>
                <span>Change Background</span>
            </button>
            <div class="container py-5"
                 v-bind:style="{ 'background-image': `url(${ background.description })` }">
                <medium-editor class="text-center mb-0 editable"
                               custom-tag="h1"
                               v-bind:text="title[lang]"
                               v-bind:options="Object.assign({ placeholder: { text: 'Title', } }, options)"
                               v-on:edit="processTitleEditOperation">
                </medium-editor>
                <medium-editor class="text-center mb-4 editable"
                               custom-tag="h3"
                               v-bind:text="subtitle[lang]"
                               v-bind:options="Object.assign({ placeholder: { text: 'Subtitle', } }, options)"
                               v-on:edit="processSubtitleEditOperation">
                </medium-editor>
                <medium-editor class="text-justify editable"
                               custom-tag="p"
                               v-bind:text="description[lang]"
                               v-bind:options="Object.assign({ placeholder: { text: 'Description', } }, options)"
                               v-on:edit="processDescriptionEditOperation">
                </medium-editor>
            </div>
        </div>
        <div class="products position-relative py-5"
             v-bind:style="{ 'background-image': `url(${ background.product })` }">
            <button class="button edit"
                    v-on:click="setProductBackground"
                    v-on:mouseover="revealImageElement"
                    v-on:mouseout="revealImageElement">
                <span class="icon is-small">
                    <font-awesome-icon icon="image"></font-awesome-icon>
                </span>
                <span>Change Background</span>
            </button>
            <div class="container">

            </div>
        </div>
        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.cover">
            <modal-set-image allow-video
                             v-bind:file.sync="coverFile"
                             v-bind:video.sync="videoCover">
                Product Series image
            </modal-set-image>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.image">
            <modal-set-image v-bind:file.sync="imageFile">
                Product series cover image
            </modal-set-image>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.background.description">
            <modal-set-image v-bind:file.sync="backgroundFile.description">
                Product Series description background image
            </modal-set-image>
        </b-modal>

        <b-modal scroll="keep" has-modal-card
                 v-bind:active.sync="isImageModalsActive.background.product">
            <modal-set-image v-bind:file.sync="backgroundFile.product">
                Product Series product background image
            </modal-set-image>
        </b-modal>
    </section>
</template>

<script>
import tinycolor from "tinycolor2";
import Chrome from "../vue-color/components/Chrome";

import editor from "vue2-medium-editor";

import BackgroundFilter from "../BackgroundFilter";
import ModalSetImage from "../modals/ModalSetImage";
import ToolBar from "../layouts/ToolBar";

const opencc = require("node-opencc");
export default {
    name: "ProductSeries",
    components: {
        ToolBar,
        ModalSetImage,
        BackgroundFilter,
        "medium-editor": editor,
        "color-picker": Chrome
    },
    props: {
        seriesId: {
            type: String,
        },
    },
    data() {
        return {
            mode: "Create",
            loading: false,
            edited: false,
            lang: "en",
            enableTcToSc: true,

            options: {
                toolbar: {
                    buttons: ["bold", "italic", "underline", "strikethrough"]
                }
            },

            isImageModalsActive: {
                cover: false,
                image: false,
                background: {
                    description: false,
                    product: false
                }
            },
            isHeadlineColorModalActive: false,

            headline: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            subheadline: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            headlineColor: {
                r: 113,
                g: 113,
                b: 113,
                a: 1
            },

            title: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            subtitle: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },
            description: {
                "en": "",
                "zh-hk": "",
                "zh-cn": ""
            },

            cover: "",
            coverFile: "",
            videoCover: {},

            image: "",
            imageFile: "",

            background: {
                description: "",
                product: ""
            },

            backgroundFile: {
                description: "",
                product: ""
            }
        }
    },
    computed: {
        series () {
            return this.$store.state.shop.series;
        },

        allowSave () {
            return this.headline["en"] &&
                this.headline["zh-hk"] &&
                this.headline["zh-cn"] &&
                this.subheadline["en"] &&
                this.subheadline["zh-hk"] &&
                this.subheadline["zh-cn"] &&
                this.title["en"] &&
                this.title["zh-hk"] &&
                this.title["zh-cn"] &&
                this.subtitle["en"] &&
                this.subtitle["zh-hk"] &&
                this.subtitle["zh-cn"] &&
                this.description["en"] &&
                this.description["zh-hk"] &&
                this.description["zh-cn"] &&
                this.cover &&
                this.image
        }
    },
    methods: {
        resetColorToDefault() {
            let color = tinycolor({
                r: 113,
                g: 113,
                b: 113,
                a: 1
            })
            this.headlineColor = {
                hex: color.toHex8String(),
                hsl: color.toHsl(),
                hsv: color.toHsv(),
                rgba: color.toRgb(),
                a: color.toRgb().a
            }
        },

        setImage() {
            this.isImageModalsActive.image = true
        },

        setCover() {
            this.isImageModalsActive.cover = true
        },

        setDescriptionBackground() {
            this.isImageModalsActive.background.description = true
        },

        setProductBackground() {
            this.isImageModalsActive.background.product = true
        },

        revealImageElement(evt) {
            evt.currentTarget.parentElement.classList.toggle("active")
        },

        processHeadlineEditOperation(operation) {
            this.headline[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.headline["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        processSubHeadlineEditOperation(operation) {
            this.subheadline[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.subheadline["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        processTitleEditOperation(operation) {
            this.title[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.title["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        processSubtitleEditOperation(operation) {
            this.subtitle[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.subtitle["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        processDescriptionEditOperation(operation) {
            this.description[this.lang] = operation.api.origElements.innerHTML
            if (this.enableTcToSc) {
                if (this.lang === "zh-hk") {
                    this.description["zh-cn"] = opencc.traditionalToSimplified(operation.api.origElements.innerHTML)
                }
            }
        },

        async save () {
            const temp = {
                headline: this.headline,
                subheadline: this.subheadline,
                title: this.title,
                subtitle: this.subtitle,
                description: this.description,
                cover: this.coverFile,
                image: this.imageFile,
                background_description: this.backgroundFile.description,
                background_product: this.backgroundFile.product
            }

            if (this.headlineColor.rgba) {
                temp.headlineColor = this.headlineColor.rgba
            }

            if (this.videoCover) {
                temp.videoCover = this.videoCover
            }

            this.loading = true
            try {
                await this.$store
                    .dispatch(
                        "shop/addSeries",
                        temp
                    );
            } catch (e) {
                console.error(e);
                this.loading = false;
                return;
            }
            this.loading = false;
            this.$router.push({
                name: "Shop-series"
            });
        },

        async update () {
            const temp = {
                _id: this._id,
                headline: this.headline,
                subheadline: this.subheadline,
                title: this.title,
                subtitle: this.subtitle,
                description: this.description,

                cover: this.coverFile,
                image: this.imageFile,
                background_description: this.backgroundFile.description,
                background_product: this.backgroundFile.product
            };

            if (this.coverFile) {
                temp.cover = this.coverFile;
            }

            if (this.imageFile) {
                temp.image = this.imageFile;
            }

            if (this.backgroundFile.description) {
                temp.background_description = this.backgroundFile.description;
            }

            if (this.backgroundFile.product) {
                temp.background_product = this.backgroundFile.product;
            }

            if (this.headlineColor.rgba) {
                temp.headlineColor = this.headlineColor.rgba;
            }

            if (this.videoCover) {
                temp.videoCover = this.videoCover;
            }

            this.loading = true;
            try {
                await this.$store
                    .dispatch(
                        "shop/updateSeries",
                        temp
                    );
            } catch (e) {
                console.error(e);
                this.loading = false;
                return;
            }
            this.loading = false;
            this.$router.push({
                name: "Shop-series"
            });
        }
    },
    mounted () {
        console.log(this.seriesId)
        if (
            this.series.length > 0 &&
            this.seriesId
        ) {
            const target = this.series.find(
                series => series._id === this.seriesId
            );
            if (target) {
                this.mode = "Update";

                this._id = target._id;
                this.headline = target.headline;
                this.subheadline = target.subheadline;
                this.title = target.title;
                this.subtitle = target.subtitle;
                this.description = target.description;
                this.cover = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/series/image/${ target.cover }`;
                this.image = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/series/image/${ target.image }`;
                this.background.description = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/series/image/${ target.background.description }`
                this.background.product = `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/series/image/${ target.background.product }`

                if (target.headlineColor) {
                    this.headlineColor = target.headlineColor
                    console.log(this.headlineColor)
                }

                if (target.videoCover) {
                    this.videoCover = target.videoCover
                }
            }
        }

        document.getElementById("app")
            .addEventListener(
                "click",
                (evt) => {
                    if (
                        !evt.target.matches(".color-picker *") &&
                        !evt.target.matches(".button-color-picker") &&
                        !evt.target.matches(".button-color-picker *")
                    ) {
                        this.isHeadlineColorModalActive = false;
                    }
                }
            );
    },
    watch: {
        "seriesId" (newVal) {
            console.log("watch", newVal)
            if (this.series.length > 0) {
                const target = this.series.find(
                    ({ _id }) => _id === newVal
                );

                if (target) {
                    this.mode = "Update";

                    this._id = target._id;
                    this.headline = target.headline;
                    this.subheadline = target.subheadline;
                    this.title = target.title;
                    this.subtitle = target.subtitle;
                    this.description = target.description;
                    this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.cover}`;
                    this.image = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.image}`;
                    this.background.description = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.background.description}`;
                    this.background.product = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.background.product}`;

                    if (target.headlineColor) {
                        this.headlineColor = target.headlineColor;
                        console.log(this.headlineColor);
                    }

                    if (target.videoCover) {
                        this.videoCover = target.videoCover;
                    }
                }
            }
        },

        "series" (newVal) {
            if (newVal.length > 0) {
                const target = newVal.find(
                    ({ _id }) => _id === this.seriesId
                );

                if (target) {
                    this.mode = "Update";

                    this._id = target._id;
                    this.headline = target.headline;
                    this.subheadline = target.subheadline;
                    this.title = target.title;
                    this.subtitle = target.subtitle;
                    this.description = target.description;
                    this.cover = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.cover}`;
                    this.image = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.image}`;
                    this.background.description = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.background.description}`;
                    this.background.product = `${this.$config.apiUrl}/${this.$config.apiVersion}/shop/series/image/${target.background.product}`;

                    if (target.headlineColor) {
                        this.headlineColor = target.headlineColor;
                        console.log(this.headlineColor);
                    }

                    if (target.videoCover) {
                        this.videoCover = target.videoCover;
                    }
                }
            }
        },

        "coverFile" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        this.cover = reader.result;
                    },
                    false
                );
                reader.readAsDataURL(newVal);
            }
        },

        "imageFile" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        this.image = reader.result
                    },
                    false
                );
                reader.readAsDataURL(newVal);
            }
        },

        "backgroundFile.description" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        this.background.description = reader.result;
                    },
                    false
                );
                reader.readAsDataURL(newVal);
            }
        },

        "backgroundFile.product" (newVal) {
            if (newVal instanceof File) {
                const reader = new FileReader();
                reader.addEventListener(
                    "load",
                    () => {
                        this.background.product = reader.result;
                    },
                    false
                );
                reader.readAsDataURL(newVal);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
$theme-colors: (
    "dark": rgba(113, 113, 113, 1)
);
@import "~bootstrap/scss/bootstrap";

.product-series {
    background-color: rgba(255, 255, 255, 1);

    .flex-1 {
        flex: 1;
    }

    & > .level {
        background-color: rgba(255, 255, 255, 1);
        padding: 0.75rem 1rem;
        margin-bottom: 0;
        box-shadow: 0 0 1px rgba(125, 125, 125, 1);

        position: sticky;
        top: 0;
        z-index: 9;

        .label {
            font-size: 20px;
            margin-bottom: 0;
        }

        .control {
            margin-bottom: 0;

            & > .button {
                &.is-active {
                    background-color: #4a4a4a;
                    color: white;
                }
            }
        }
    }

    button {
        &.edit {
            position: absolute;
            z-index: 8;
        }
    }

    .editable {
        color: rgba(74, 74, 74, 1) !important;
        position: relative;
        border-bottom: dashed 2px #8c67ef;

        &:focus {
            background-color: rgba(255, 255, 255, 1);
            border-radius: 4px;
            border: solid 2px rgba(125, 125, 125, 1);
            outline: none;
        }

        &:after {
            position: absolute;
            bottom: -24px;
            right: 0;
            content: "Click to edit";
            font-size: 14px;
            font-weight: normal;
            padding: 3px;
        }
    }

    & > .banner {
        min-height: 35vh;

        & > button.edit {
            bottom: 5px;
            right: 5px;
        }

        .img-responsive {
            height: auto;
            max-height: 500px;
            max-width: 100%;
        }

        .product-image {
            & > button.edit {
                bottom: 0;
                right: 0;
            }

            &.active {
                border: dashed 2px #ff1f4b;
            }
        }

        &.active {
            border: dashed 2px #ff1f4b;
        }
    }

    & > .description {
        min-height: 30vh;

        & > .container {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }

        &.active {
            border: dashed 2px #ff1f4b;
        }
    }

    & > .products {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;

        min-height: 600px;

        &.active {
            border: dashed 2px #ff1f4b;
        }
    }

    .color-picker {
        display: none;
        top: 0;
        left: 0;
        z-index: 10;

        &.show {
            display: block;
        }
    }

    .button-color-default {
        text-align: center;
        padding: 0.5rem;
        padding-top: 0;
    }
}
</style>
